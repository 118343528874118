import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import { List, ListItem } from "@material-ui/core";
// core components
import Header from "components/Header/Header.jsx";
import HeaderLinks from "components/Header/HeaderLinks.jsx";
import Footer from "components/Footer/Footer.jsx";
import Parallax from "components/Parallax/Parallax.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";


import ContactForm from "components/ContactForm/ContactForm.jsx";

import contactPageStyle from "assets/jss/material-kit-react/views/contactPage.jsx";

import classNames from "classnames";

class ContactPage extends React.Component {
  render() {
    const { classes, ...rest } = this.props;
    return (
      <div>
          <Header
              color="transparent"
              brand="St Heliers Psychology"
              rightLinks={<HeaderLinks />}
              fixed
              changeColorOnScroll={{
                  height: 200,
                  color: "white"
              }}
              {...rest}
          />
          <Parallax small filter image={require("assets/img/page-bg.jpg")} />
          <div className={classNames(classes.main)}>
              <div className={classes.section}>
                  <div className={classes.container}>
                      <GridContainer justify="center">
                          <GridItem xs={12} sm={12} md={8}>
                              <h2 className={classes.title}>Request an appointment</h2>
                              <p className={classes.description}>
                                  Please phone, email or fill out the contact form below to book a phone consultation or a first appointment.
                              </p>
                              <p className={classes.description}>
                                  Location: Fillan Healthcare, East Med Medical Centre. Parking is available on-site. There is a comfortable waiting area with complimentary tea/coffee.
                              </p>
                              <p className={classes.description}>
                                  Appointments: Daytime and evening appointments available.
                              </p>
                          </GridItem>
                      </GridContainer>
                  </div>
              </div>
              <div className={classes.sections}>
                  <div className={classes.container}>
                      <GridContainer>
                          <GridItem xs={12} sm={12} md={5}>
                              <List className={classes.list}>
                                  <ListItem >
                                      <Button justIcon href="mailto:drjudithelder@gmail.com" target="_blank" rel="noopener noreferrer" color="primary" >
                                          <i className={"fas fa-envelope"} />
                                      </Button>
                                      <a className={"contacts"} href="mailto:drjudithelder@gmail.com" target="_blank" rel="noopener noreferrer"><h5 style={{paddingLeft: 10}}>drjudithelder@gmail.com</h5></a>
                                  </ListItem>
                                  <ListItem >
                                      <Button justIcon href="tel:02102591752" target="_blank" rel="noopener noreferrer" color="primary" >
                                          <i className={"fas fa-phone"} />
                                      </Button>
                                      <a className={"contacts"} href="tel:02102591752" target="_blank" rel="noopener noreferrer"><h5 style={{paddingLeft: 10}}>+64 (0)21 0259 1752 </h5></a>
                                  </ListItem>
                                  <ListItem >
                                      <Button justIcon color="primary" >
                                          <i className={"fas fa-map-marker-alt"} />
                                      </Button>
                                      <a className={"contacts"} href="https://maps.google.com/maps?q=188%20St%20Heliers%20Bay%20Rd%2C%20St%20Heliers%2C%20Auckland%20107&t=&z=13&ie=UTF8&iwloc" target="_blank" rel="noopener noreferrer"><h5 style={{paddingLeft: 10}}>188 ST HELIERS BAY RD, ST HELIERS, AUCKLAND 1071</h5></a>
                                  </ListItem>
                              </List>
                              <div id="map-container-google-13" className="z-depth-1-half map-container-8" style={{marginLeft: 20}}>
                                  <iframe title={"map"} src="https://maps.google.com/maps?q=188%20St%20Heliers%20Bay%20Rd%2C%20St%20Heliers%2C%20Auckland%201071&t=&z=13&ie=UTF8&iwloc=&output=embed" frameBorder="0"  allowFullScreen></iframe>
                              </div>
                          </GridItem>
                          <GridItem xs={false} sm={false} md={1}>
                          </GridItem>
                          <GridItem xs={12} sm={12} md={6}>
                              <ContactForm />
                          </GridItem>
                      </GridContainer>
                  </div>
              </div>

          </div>
          <Footer />

      </div>
    );
  }
}

export default withStyles(contactPageStyle)(ContactPage);
