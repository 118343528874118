import React from "react";
import { Link } from "react-router-dom";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";


import serviceStyle from "assets/jss/material-kit-react/views/landingPageSections/serviceStyle.jsx";


class ServiceSection extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.section}>
        <div className={classes.container}>
            <GridContainer justify="center">
                <GridItem xs={12} sm={12} md={8}>
                  <h2 className={classes.title}>Therapy Approaches</h2>
                    <p className={classes.description}>
                        Using a collaborative, warm style, I aim to help you to make sense of your difficulties;  I am interested in the ways some of the experiences in your life may have shaped your beliefs, resulting in particular ways of behaving and relating to others. Together, we can start to understand some of these patterns, and can consider new ways of coping and relating to others</p>
                </GridItem>
            </GridContainer>
            <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                    <Card>
                        <h4 className={classes.cardTitle}>
                            Eye Movement Desensitization and Reprocessing
                        </h4>
                        <CardBody>
                            <p className={classes.description}>
                                EMDR is a structured, safe treatment which allows people to heal from traumatic and disturbing life experiences.
                            </p>
                        </CardBody>
                        <CardFooter className={classes.justifyRight}>
                            <Link to={"/services#emdr"} className={classes.link}>
                                <Button shape="round" color="danger" size="lg">
                                    Read More
                                </Button>
                            </Link>
                        </CardFooter>
                    </Card>
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                    <Card>
                        <h4 className={classes.cardTitle}>
                            Cognitive Behavioural Therapy
                        </h4>
                        <CardBody>
                            <p className={classes.description}>
                                CBT is based on the connection between thoughts, emotions, physical sensations and behaviour.
                            </p>
                        </CardBody>
                        <CardFooter className={classes.justifyRight}>
                            <Link to={"/services#cbt"} className={classes.link}>
                                <Button shape="round" color="danger" size="lg">
                                    Read More
                                </Button>
                            </Link>
                        </CardFooter>
                    </Card>
                </GridItem>
            </GridContainer>
          </div>
        </div>
    );
  }
}

export default withStyles(serviceStyle)(ServiceSection);
