import React from "react";
import axios from 'axios';
import {Formik, Form} from 'formik';
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";

import contactStyle from "assets/jss/material-kit-react/views/landingPageSections/contactStyle.jsx";

class ContactForm extends React.Component {

    render() {
        const { classes } = this.props;
        return (
            <Formik
                initialValues={{
                    email: "",
                    name: "",
                    phone: "",
                    message: ""
                }}
                validate={values => {
                    const errors = {};
                    if (!values.email)
                        errors.email = "Required";

                    if (!values.name)
                        errors.name = "Required";

                    if (!values.message)
                        errors.message = "Required";

                    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
                        errors.email = "You must supply a valid email address";
                    }

                    return errors;
                }}
                onSubmit={(values, {setSubmitting, resetForm}) => {
                    console.log("Submitted Values:", values);
                    // Post to provider
                    let url = 'https://api.sthelierspsychology.com/api/contact';
                    axios.post(url, values)
                        .then(function(response){
                            console.log(response);
                            setSubmitting(false);
                            resetForm();
                            alert("Thank you! I have received your enquire.");
                        })
                        .catch(function(error){
                            console.log(error);
                            setSubmitting(false);
                            alert("Whoops! It looks like something went wrong. Please try again or call or email.");
                        });
                }}
                render={({ values, errors, touched, isSubmitting, handleChange, handleBlur, handleSubmit }) => (

                    <Form onSubmit={handleSubmit}>
                        <GridContainer>
                            <GridItem xs={12} sm={12} md={6}>
                                <CustomInput
                                    labelText="Your Name"
                                    id="name"
                                    formControlProps={{
                                        fullWidth: true,
                                        required: true
                                    }}
                                    inputProps={{
                                        value: values.name,
                                        onChange: handleChange,
                                        onBlur: handleBlur,
                                    }}
                                />

                            </GridItem>
                            <GridItem xs={12} sm={12} md={6}>
                                <CustomInput
                                    labelText="Your Email"
                                    id="email"
                                    formControlProps={{
                                        fullWidth: true,
                                        required: true
                                    }}
                                    inputProps={{
                                        value: values.email,
                                        onChange: handleChange,
                                        onBlur: handleBlur
                                    }}
                                />
                            </GridItem>
                            <CustomInput
                                labelText="Your Message"
                                id="message"
                                formControlProps={{
                                    fullWidth: true,
                                    required: true,
                                    className: classes.textArea
                                }}
                                inputProps={{
                                    multiline: true,
                                    rows: 5,
                                    value: values.message,
                                    onChange: handleChange,
                                    onBlur: handleBlur
                                }}
                            />
                        </GridContainer>

                        <GridContainer>
                            <GridItem xs={12} sm={12} md={4} className={classes.textCenter}>
                                <Button type="submit" value="Submit" disabled={isSubmitting} className="btn btn-primary" color="primary">Send Message</Button>
                            </GridItem>
                        </GridContainer>
                    </Form>
            )} />
        );
    }
}

export default withStyles(contactStyle)(ContactForm);
