import "@babel/polyfill";
import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch } from "react-router-dom";
import { polyfill } from 'es6-promise';

import "assets/scss/material-kit-react.scss";

// pages for this website
import LandingPage from "views/LandingPage/LandingPage.jsx";
import ProfilePage from "views/ProfilePage/ProfilePage.jsx";
import ContactPage from "views/ContactPage/ContactPage.jsx";
import ServicesPage from "views/ServicesPage/ServicesPage.jsx";

polyfill();
var hist = createBrowserHistory();

ReactDOM.render(
  <Router history={hist}>
    <Switch>
        <Route exact path="/" component={LandingPage} />
        <Route exact path="/about-me" component={ProfilePage} />
        <Route exact path="/contact" component={ContactPage} />
        <Route exact path="/services" component={ServicesPage} />
        { /* Finally, catch all unmatched routes */ }
        <Route exact component={LandingPage} />
    </Switch>
  </Router>,
  document.getElementById("root")
);
