/*eslint-disable*/
import React from "react";
// react components for routing our app without refresh
import { Link } from "react-router-dom";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";

// @material-ui/icons

// core components

import headerLinksStyle from "assets/jss/material-kit-react/components/headerLinksStyle.jsx";

function HeaderLinks({ ...props }) {
  const { classes } = props;
  return (
    <List className={classes.list}>
        <ListItem className={classes.listItem}>
            <Link
                to="about-me"
                className={classes.navLink}
                color="transparent"
            >
                About me
            </Link>
        </ListItem>
        <ListItem className={classes.listItem}>
            <Link
                to="services"
                className={classes.navLink}
                color="transparent"
            >
                Services
            </Link>
        </ListItem>
        <ListItem className={classes.listItem}>
            <Link
                to="/contact"
                className={classes.navLink}
                color="transparent"
            >
                Contact
            </Link>
        </ListItem>
    </List>
  );
}

export default withStyles(headerLinksStyle)(HeaderLinks);
