import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// @material-ui/icons

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import ContactForm from "components/ContactForm/ContactForm.jsx";

import contactStyle from "assets/jss/material-kit-react/views/landingPageSections/contactStyle.jsx";

class ContactSection extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.section}>
          <div className={classes.container}>
            <GridContainer justify="center">
              <GridItem cs={12} sm={12} md={8}>
                <h2 className={classes.title}>Contact</h2>
                <h4 className={classes.description}>
                  Please fill in this contact form or call or email directly to discuss how I can help
                </h4>
                <ContactForm />
              </GridItem>
            </GridContainer>
          </div>
      </div>
    );
  }
}

export default withStyles(contactStyle)(ContactSection);
