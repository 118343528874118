import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import Header from "components/Header/Header.jsx";
import Footer from "components/Footer/Footer.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import HeaderLinks from "components/Header/HeaderLinks.jsx";
import Parallax from "components/Parallax/Parallax.jsx";

import basicPageStyle from "assets/jss/material-kit-react/views/basicPage.jsx";

class ProfilePage extends React.Component {
  render() {
    const { classes, ...rest } = this.props;
    return (
      <div>
        <Header
          color="transparent"
          brand="St Heliers Psychology"
          rightLinks={<HeaderLinks />}
          fixed
          changeColorOnScroll={{
            height: 200,
            color: "white"
          }}
          {...rest}
        />
        <Parallax small filter image={require("assets/img/page-bg.jpg")} />
        <div className={classNames(classes.main)}>
            <div className={classes.sections}>
                <div className={classes.container}>
                    <GridContainer justify="center">
                        <GridItem xs={12} sm={12} md={8}>
                            <h2 className={classes.title}>Experience</h2>
                            <div className={classes.description}>
                                <p>
                                    I have over 10 years of experience working in mental health services in New Zealand and the United Kingdom, often working with people with chronic and complex mental health difficulties, both in the community and on inpatient wards.
                                </p>
                                <p>
                                    In addition to working with adults with mental health problems, I have experience working with children and adolescents, older adults and people with intellectual disabilities. I also have particular expertise in working with women in pregnancy and during the post partum period. I also have experience of conducting research, evaluating health services and training professionals.
                                </p>
                                <p>
                                    Currently, I split my time between the District Health Board (DHB) and working in private practice.
                                </p>
                            </div>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={8}>
                            <h2 className={classes.title}>Qualifications</h2>
                            <div className={classes.description}>
                                <ul>
                                    <li>
                                        Doctorate of Clinical Psychology (University College London, UK) - 2011
                                    </li>
                                    <li>
                                        Experimental Psychology, Bachelor of Science (The University of Bristol, UK)
                                    </li>
                                </ul>
                            </div>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={8}>
                            <h2 className={classes.title}>Registrations</h2>
                            <div className={classes.description}>
                                <ul>
                                    <li>
                                        Registered Clinical Psychologist with New Zealand Psychologist Board
                                    </li>
                                    <li>
                                        Member of the New Zealand College of Clinical Psychologists
                                    </li>
                                    <li>
                                        Annual Practising Certificate
                                    </li>
                                    <li>
                                        United Kingdom Health Care and Professionals Council Member
                                    </li>
                                    <li>
                                        EMDR Association of Australia Member
                                    </li>
                                </ul>
                            </div>
                        </GridItem>
                    </GridContainer>
                </div>
            </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default withStyles(basicPageStyle)(ProfilePage);
