import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import Header from "components/Header/Header.jsx";
import Footer from "components/Footer/Footer.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import HeaderLinks from "components/Header/HeaderLinks.jsx";
import Parallax from "components/Parallax/Parallax.jsx";

import basicPageStyle from "assets/jss/material-kit-react/views/basicPage.jsx";

class ServicesPage extends React.Component {
  render() {
    const { classes, ...rest } = this.props;
    return (
      <div>
        <Header
          color="transparent"
          brand="St Heliers Psychology"
          rightLinks={<HeaderLinks />}
          fixed
          changeColorOnScroll={{
            height: 200,
            color: "white"
          }}
          {...rest}
        />
          <Parallax small filter image={require("assets/img/page-bg.jpg")} />
        <div className={classNames(classes.main)}>
            <div className={classes.section}>
                <div className={classes.container}>
                    <GridContainer  justify="center">
                        <GridItem xs={12} sm={12} md={8}>
                            <h2 className={classes.title}>Services</h2>
                            <div className={classes.description}>
                                <p>
                                    I will start by offering a free, short (15 minute) phone consultation to ensure that I am the right person to help you with your goals.
                                </p>
                                <p>
                                    We can then arrange to meet for our first assessment session, which will last for 1 hour and 30 minutes to allow us time to thoroughly discuss your presenting difficulties.
                                </p>
                                <p>
                                    We will then be in a position to develop a treatment plan, including the models of therapy I can draw on and the number of sessions I can offer (typically between 4-8 sessions).
                                </p>
                                <p>
                                    The therapy I offer you will be carefully tailored to an individual formulation, which could be understood as a ‘map’ of your difficulties. All of our work will follow your personal goals, and I will ensure that our time together is spent efficiently as possible. It is important that we are able to form a strong therapeutic relationship as this is an important part of the healing process.
                                </p>
                                <p>
                                    All of the information you share with me will remain completely confidential, with some specific exceptions which we can discuss when we meet. I can communicate with your GP or other health professionals with your consent.
                                </p>

                            </div>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={8}>
                            <h2 className={classes.title}>Fees</h2>
                            <div className={classes.description}>
                                <table>
                                    <thead>
                                    <tr>
                                        <th>Session</th>
                                        <td> </td>
                                        <th>Cost ($)</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                    </tr>
                                    <tr>
                                        <td>15 minute phone consultation</td>
                                        <td> </td>
                                        <td>Free</td>
                                    </tr>
                                    <tr>
                                        <td> </td>
                                        <td> </td>
                                        <td> </td>
                                    </tr>
                                    <tr>
                                        <td>Assessment session (90 mins)</td>
                                        <td> </td>
                                        <td>220</td>
                                    </tr>
                                    <tr>
                                        <td> </td>
                                        <td> </td>
                                        <td> </td>
                                    </tr>
                                    <tr>
                                        <td>CBT session (60 mins)</td>
                                        <td> </td>
                                        <td>180</td>
                                    </tr>
                                    <tr>
                                        <td> </td>
                                        <td> </td>
                                        <td> </td>
                                    </tr>
                                    <tr>
                                        <td>EMDR processing session (75 mins)</td>
                                        <td> </td>
                                        <td>200</td>
                                    </tr>
                                    </tbody>

                                </table>
                            </div>
                            <div className={classes.description}>
                                <p>Please provide 5 working days for rescheduling or cancelling appointments, otherwise a 50% fee applies.</p>
                                <p>Southern Cross clients accepted (for policies including Clinical Psychology). This can be claimed directly. Please send me your membership number by email prior to your appointment.</p>
                                <p>Video and phone sessions also available if required.</p>
                            </div>
                        </GridItem>

                        <GridItem xs={12} sm={12} md={8}>
                            <h2 className={classes.title}>Supervision Services</h2>
                            <div className={classes.description}>
                                <p>
                                    I am available for clinical supervision for psychologists and other registered professionals who practice evidence-based therapies.
                                </p>
                            </div>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={8}>
                            <a href={"#cbt"}> <h2 className={classes.title}>CBT</h2></a>
                            <div className={classes.description}>
                                <p>Cognitive Behavioural Therapy (CBT) is based on the connection between thoughts, emotions, physical sensations and behaviour.</p>
                                <p>It follows the theory that negative thoughts and feelings can create ‘traps’ or ‘vicious cycles’ which can be very difficult to escape from (for example someone who is experiencing low mood may struggle to engage in activities that would normally provide them with pleasure or achievement, thus further worsening their mood). It is also based on the understanding that early life experiences can lead us to develop core beliefs about ourselves, which can contribute to our negative thoughts.</p>
                                <p>CBT is a collaborative, structured therapy in which the client brings their expertise about themselves, and the therapist brings their knowledge of psychological processes to form a team to move forward with the client’s personal goals. It focuses on making changes in the ‘here and now’, although a CBT formulation will also involve discussion of how difficulties may have developed and may involve working at a deeper level with core beliefs. CBT requires a commitment on behalf of the client to work on weekly goals between sessions. CBT has a excellent evidence base for treating a wide range of psychological difficulties.</p>
                            </div>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={8}>
                            <a href={"#emdr"}> <h2 className={classes.title}>EMDR</h2></a>
                            <div className={classes.description}>
                                <p>Eye Movement Desensitization and Reprocessing (EMDR) is a structured, safe treatment which allows people to heal from traumatic and disturbing life experiences.</p>
                                <p>It follows the principle that the mind can recover from psychological trauma in the same way that the body recovers from physical trauma. EMDR facilitates the processing of traumatic experiences, in which the brain’s information processing system works naturally to overcome ‘blocks’, to recall new information and overcome painful emotions.</p>
                                <p>It uses a detailed, structured protocol which is used internationally and has been extensively researched. It has an excellent, international evidence base for the treatment of Post Traumatic Stress Disorder and Trauma. For more information please see the website for the EMDR Association of Australia <a href={"https://emdraa.org"}>(Link)</a>.</p>
                            </div>
                        </GridItem>
                    </GridContainer>
                </div>
            </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default withStyles(basicPageStyle)(ServicesPage);
