import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// @material-ui/icons

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import introStyle from "assets/jss/material-kit-react/views/landingPageSections/introStyle.jsx";
import profile from "assets/img/faces/jude.jpg"

class IntroSection extends React.Component {
  render() {
    const { classes } = this.props;
    return (
        <div className={classes.section}>
            <div className={classes.container}>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={4} className={classes.itemGrid}>
                        <div className={classes.profile}>
                            <img src={profile} alt="..." className={classes.imgRoundedCircle} />
                        </div>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={8}>
                        <h2 className={classes.title}>Dr Judith Elder</h2>
                        <h5 className={classes.subTitle}>
                            <p>(BSc, DClinPsych)</p>
                            Registered Clinical Psychologist
                        </h5>
                        <p className={classes.description}>
                            I believe that therapy can enable and empower everyone to lead a happy and meaningful life. I work using an integration of a number of evidenced based therapeutic models, but with a focus on Cognitive Behavioural Therapy (CBT) and Eye Movement Desensitization and Reprocessing (EMDR). Using a collaborative, warm style, I aim to help you to make sense of your difficulties;  I am interested in the ways some of the experiences in your life may have shaped your beliefs, resulting in particular ways of behaving and relating to others. Together, we can start to understand some of these patterns, and can consider new ways of coping and relating to others.
                        </p>
                        <p className={classes.description}>I have expertise in working with people who are experiencing:</p>
                        <div className={classes.description}>
                            <ul>
                            <li>Trauma & PTSD (including accidents, birth trauma, sexual assault, childhood sexual abuse or other traumatic events)</li>
                            <li>Anxiety difficulties (including panic, social anxiety, generalised anxiety, OCD, phobias)</li>
                            <li>Depression</li>
                            <li>Body image and eating difficulties</li>
                            <li>Stress, burnout and perfectionism</li>
                            <li>Difficulties with shame and self criticism</li>
                            <li>Low self esteem and relationship problems</li>
                        </ul>
                        </div>
                        <p className={classes.description}>
                            In my work, I draw closely on CBT, EMDR, Compassion Focused Therapy, Dialectical Behaviour Therapy (DBT) and Cognitive Analytic Informed Therapy. I also draw on systemic theory and have training in conducting Single Session Family Consultations.
                        </p>
                        <p className={classes.description}>
                            The current COVID 19 situation raises many challenges for our mental health. I am currently available for video therapy sessions (this usually excludes EMDR), which includes reading and homework resources sent by email. You can still start with a free phone consultation prior to booking your video session. Southern Cross can cover video sessions during this period.
                        </p>
                    </GridItem>
                </GridContainer>
            </div>
        </div>
    );
  }
}

export default withStyles(introStyle)(IntroSection);
