import { container, title } from "assets/jss/material-kit-react.jsx";

const serviceStyle = {
  section: {
    padding: "70px 0",
    textAlign: "center",
      background: "#EEEEEE"
  },
    container,
  title: {
    ...title,
    marginBottom: "1rem",
    marginTop: "30px",
    minHeight: "32px",
    textDecoration: "none"
  },
  description: {
    color: "#696969"
  },
    justifyRight: {
        color: "#696969"
    }
};

export default serviceStyle;
