/*eslint-disable*/
import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";
import { List, ListItem, withStyles } from "@material-ui/core";

import Button from "components/CustomButtons/Button.jsx";

// @material-ui/icons
import Favorite from "@material-ui/icons/Favorite";

import footerStyle from "assets/jss/material-kit-react/components/footerStyle.jsx";

function Footer({ ...props }) {
  const { classes, whiteFont } = props;
  const footerClasses = classNames({
    [classes.footer]: true,
    [classes.footerWhiteFont]: whiteFont
  });
  const aClasses = classNames({
    [classes.a]: true,
    [classes.footerWhiteFont]: whiteFont
  });
  return (
    <footer className={footerClasses}>
      <div className={classes.container}>
        <div className={classes.left}>
          <List className={classes.list}>
            {/*<ListItem className={classes.inlineBlock}>*/}
                {/*<Button justIcon color="transparent" href="https://linkedin.com/" target="_blank" className={classes.margin5}>*/}
                    {/*<i className={classes.socials + " fab fa-linkedin"} />*/}
                {/*</Button>*/}
            {/*</ListItem>*/}
              <ListItem className={classes.inlineBlock}>
                  <Button justIcon color="transparent" href="tel:02102591752" target="_blank" className={classes.margin5} >
                      <i className={classes.socials + " fas fa-phone"} />
                  </Button>
              </ListItem>
              <ListItem className={classes.inlineBlock}>
                  <Button justIcon color="transparent" href="mailto:drjudithelder@gmail.com" target="_blank" className={classes.margin5} >
                      <i className={classes.socials + " fas fa-envelope"} />
                  </Button>
              </ListItem>
          </List>
        </div>
        <div className={classes.right}>
          &copy; {1900 + new Date().getYear()}, Judith Elder.
        </div>
      </div>
    </footer>
  );
}

Footer.propTypes = {
  classes: PropTypes.object.isRequired,
  whiteFont: PropTypes.bool
};

export default withStyles(footerStyle)(Footer);
