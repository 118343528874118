import { container, title, sub } from "assets/jss/material-kit-react.jsx";

const introStyle = {
  section: {
      padding: "70px 0",
    textAlign: "center"
  },
  title: {
    ...title,
      textAlign: "left",
    marginBottom: "1rem",
    marginTop: "30px",
    minHeight: "32px",
    textDecoration: "none"
  },
    subTitle: {
        ...title,
        textAlign: "left",
        fontSize: "1.313rem",
        maxWidth: "500px",
        marginBottom: "1rem",
        marginTop: "10px"
    },
  description: {
      textAlign: "left",
    color: "#696969"
  },
  itemGrid: {
      marginLeft: "auto",
      marginRight: "auto"
  },
  container,
  profile: {
      textAlign: "center",
      "& img": {
          maxWidth: "240px",
          width: "100%",
          margin: "0 auto",
          borderRadius: "50%"
      }
  },
  main: {
      background: "#FFFFFF",
      position: "relative",
      zIndex: "3"
  },
  mainRaised: {
      margin: "-60px 30px 0px",
      borderRadius: "6px",
      boxShadow:
          "0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)"
  },
  socials: {
      marginTop: "0",
      width: "100%",
      transform: "none",
      left: "0",
      top: "0",
      height: "100%",
      lineHeight: "41px",
      fontSize: "20px",
      color: "#999"
  },
  navWrapper: {
      margin: "20px auto 50px auto",
      textAlign: "center"
  }
};

export default introStyle;
